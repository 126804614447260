<script>
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {  helpers,  required, minLength } from "@vuelidate/validators";
import myLoader from "@/components/myLoader.vue";
import { leerPunto, editarPunto, borrarPunto } from "@/api/points";

const coordenada = helpers.regex(/^-?(\d*\.\d{6,}|\.\d{6,})$/);

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  
  data() {
    return {
      visible:false,
      PointsNumId:null,
      Description:null,
      isLoader:false,
      divisionTerritorial:[],
      TypePoint:'F',
      Latitude:null,
      Longitude:null,
      Status:'A',
      submitted:null,
    }
  },
  props: {
    coordinateNew: { type: Object, required: false },
    PointsNum: { type: String, required: false,
      default(){
          return 'P1'
      }
    },
    rutaActual: { type: Object, required: false,
      default(){
          return {
              codigo:'XXX',
              nombre: 'Nombre',
          }
      }
    },
  },
  watch: {
    PointsNum(data){
      if(data=='' || data==null ) return;
      this.PointsNumId=data;
      if(data!='PX'){
        this.leerDatosPunto(data);
      }
    },
  },
 
  components: {
    myLoader,
  },
  validations: {
    PointsNum: {
      required: helpers.withMessage("el ID es requerido", required),
      minLength: helpers.withMessage("Minimo 2 ", minLength(2))
       
    },
    Description: {
      required: helpers.withMessage("el nombre es requerido", required),
    },
    Latitude:{
      required: helpers.withMessage("la latitud se requiere", required),
      coordenada: helpers.withMessage("tres (3) enteros y seis (6) decimales", coordenada),
    },
    Longitude:{
      required: helpers.withMessage("la longitud se requiere", required),
      coordenada: helpers.withMessage("tres (3) enteros y seis (6) decimales", coordenada),
    },
  },  
  methods: {
    tryCancel() {
       // Verifica si trae nuevas coordinadas (draggable)
       console.log("coordinateNew-edit-79",this.coordinateNew)
        if(this.coordinateNew.lat!=0 && this.coordinateNew.lng!=0){
          this.Latitude=this.coordinateNew.lat;
          this.Longitude=this.coordinateNew.lng;
          this.$emit("newPoint",this.rutaActual.codigo);
        }
      
    },
    tryDelete() {
      Swal.fire({
      title: 'Desea Borrar este punto?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          this.delete();
        } else if (respuesta.isDenied) {
          Swal.fire('No borrado', '', 'info')
        }
      })

    },  
    trySubmit() {
     
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        // Aquí podrías realizar la lógica para guardar los datos
        this.save();
      }
      this.isLoader = false;
    },
    async delete() {
      this.isLoader=true
      let body={
        "PointsNum":this.PointsNum,
      }
      const result2 = await borrarPunto(body);
      this.isLoader=false;
      if (result2.data.code==200){
        document.getElementById("btnCloseModalPointEdit").click();
        Swal.fire("Borrado de Punto!", "Proceso Exitoso!", "success");
        this.$emit("newPoint",this.rutaActual.codigo);
      }else{  
        Swal.fire("Borrado de Punto!", result2.data.data[0], "error");
      }    
    },
    async save() {
      this.isLoader=true
      let body={
        "CodeRoute":this.rutaActual.codigo,
        "PointsNum":this.PointsNum,
        "Description":this.Description,
        "Latitude":this.Latitude.toString(),
        "Type":this.TypePoint,
        "Longitude":this.Longitude.toString(),
      }
      const result = await editarPunto(body);
      this.isLoader=false;
      if (result.data.code==200){
        document.getElementById("btnCloseModalPointEdit").click();
        Swal.fire("Modificando Punto!", "Proceso Exitoso!", "success");
        this.$emit("newPoint",this.rutaActual.codigo);
      }else{  
        Swal.fire("Modificando Punto!", result.data.data[0], "error");
      }    
    },
    async leerDatosPunto(PointsNum){
      this.isLoader=true
      let body={
        "PointsNum":PointsNum,
        "Latitude":0,
        "Longitude":0,
      }
      const result = await leerPunto(body);
      this.isLoader=false;
      if (result.data.code==200){
        var rec=result.data.data[0];
        this.Description=rec.Description;
        this.Latitude=rec.Latitude;
        this.TypePoint=rec.Type;
        this.Longitude=rec.Longitude;
        // Si trae nuevas coordinadas (draggable)
        if(this.coordinateNew.lat!=0 && this.coordinateNew.lng!=0){
          this.Latitude=this.coordinateNew.lat;
          this.Longitude=this.coordinateNew.lng;
        }
      }else{
        Swal.fire("Punto no fue encontrado!", "Proceso no se Realizo con éxito!", "error");
      }   
    }, 
  },  
  mounted() {
  }   
}  
</script>


<!-- Default Modals -->
<template>
  <div id="myModalPointEdit" class="modal fade" tabindex="-1" aria-labelledby="myModalPointEditLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg drawer-dialog-right">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalPointEditLabel">Edición de Puntos</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        
          <span id="customText" class="text-dark text-right mr-2 ml-2">
            {{ rutaActual.codigo }} - {{ rutaActual.nombre }} 
          </span> 
       
        <div class="modal-body">
          <form id="createForm" @submit.prevent="trySubmit">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-2">
                  <label for="PointsNum" class="form-label"
                    >Id</label
                  >
                
                  <input
                    type="text"
                    class="form-control"
                    id="PointsNum"
                    placeholder="ID Punto"
                    v-model="PointsNumId"
                    :class="{
                      'is-invalid': submitted && v$.PointsNum.$error,
                    }"
                    readonly
                  />
                  <div
                    v-for="(item, index) in v$.PointsNum.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <label class="form-label">Tipo</label><br>
                <div class="mt-4 mt-lg-0">
                  <select
                   class="form-select mb-3"
                    aria-label="Tipo de Punto"
                    v-model="TypePoint"
                  >
                    <option value="G">Estación de Gasolina</option>
                    <option value="H">Hotel</option>
                    <option value="P">Parqueadero</option>
                    <option value="R">Taller de Reparación</option>
                    <option value="F">Restaurante</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
            <div class="mb-2">
              <label for="Description" class="form-label"
                >Nombre del punto</label
              >
              <input
                type="text"
                class="form-control"
                id="Description"
                placeholder="Nombre del punto"
                v-model="Description"
                :class="{
                  'is-invalid': submitted && v$.Description.$error,
                }"
              />
              <div
                v-for="(item, index) in v$.Description.$errors"
                :key="index"
                class="invalid-feedback"
              >
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
          </div>
        </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label class="form-label">Latitud </label>
                <input
                  type="number"  
                  step="0.000001"
                  class="form-control"
                  id="Latitude"
                  placeholder="Latitud"
                  v-model="Latitude"
                  :class="{
                    'is-invalid': submitted && v$.Latitude.$error,
                  }"
                />
                <div class="text-danger fs-7"
                    v-for="(item, index) in v$.Latitude.$errors"
                    :key="index"
                  >
                  <span  v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label">Longitud</label>
                <input
                  type="number"  
                  step="0.000001"
                  class="form-control"
                  id="Longitude"
                  placeholder="Longitud"
                  v-model="Longitude"
                  :class="{
                    'is-invalid': submitted && v$.Longitude.$error,
                  }"
                />
                <div class="text-danger fs-7"
                v-for="(item, index) in v$.Longitude.$errors"
                :key="index"
              >
              <span  v-if="item.$message">{{ item.$message }}</span>
            </div>
              </div>
            </div>  
          </form>  
        </div>
        <div class="modal-footer">
          <button type="button" id="btnCloseModalPointEdit" class="btn btn-light" data-bs-dismiss="modal"
              @click="tryCancel">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="trySubmit">Grabar</button>
          <button type="button" class="btn btn-danger" @click="tryDelete">Borrar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoader" style="position:absolute; top: 35%; 
    right:10%;
    z-index: 9999;  "  >
    <myLoader /> 
</div>
</template>


