import axios from "axios";
import { authHeader } from "../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;


//Funcion Leer codigo siguiente del PUNTO
const leerSiguiente = async () => {
  try {
    const result = await axios.get(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Points/leerSiguiente`, 
    { headers: authHeader()}
    );
    return { result };
  } catch (error) {
    if (error.response) {
      // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};


const crearPunto = async (body) => {
  try {                                       
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Points/crear`, 
      body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
      // Limpiar localstoreage al detectar token vencido
      return error.response; // => the response payload
  }
};

const leerPunto = async (body) => {  try {                                       
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Points/leer`, 
      body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
      // Limpiar localstoreage al detectar token vencido
      return error.response; // => the response payload
  }
};

const editarPunto = async (body) => {
  try {                                       
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Points/editar`, 
      body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
      // Limpiar localstoreage al detectar token vencido
      return error.response; // => the response payload
  }
};

const borrarPunto = async (body) => {
  try {                                       
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Points/borrar`, 
      body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
      // Limpiar localstoreage al detectar token vencido
      return error.response; // => the response payload
  }
};




export { leerSiguiente, crearPunto, leerPunto, editarPunto, borrarPunto};
