<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import mapRouter from "@/components/maps/leaflet/mapsRouters";
import {leerRutas, leerRutaPuntos} from "@/api/routers";
import { leerSiguiente } from "@/api/points";
import myLoader from "@/components/myLoader.vue";
import Create from './create.vue';
import Edit from './edit.vue';
import CreatePoints from '../points/create.vue';
import EditPoints from '../points/edit.vue';
import {leerDivisionTerritorial} from "@/api/system";

export default {
  page: {
    title: "Gestión de rutas",
    meta: [{ name: "Gestión de rutas", content: appConfig.description }],
  },
  data() {
    return {
      title: "Gestión de rutas",
      items: [
        {
          text: "Gestión de rutas",
          href: "/configuration/routes",
        },
        {
          text: "Gestión de rutas",
          active: true,
        },
      ],
      isLoader:false,
      dataRouters:[],
      dataPoints:[],
      coordinateNew:{lat:0,lng:0},
      divisionTerritorial:[],
      search:"",
      codeRouteId:'',
      codePointId:'',
      PointsNum:null,
      rutaActual:{codigo: "",nombre: "",},
      LatLng:{lat:0,lng:0}
    };
  },
  components: {
    Layout,
    PageHeader,
    mapRouter,
    myLoader,
    Create,
    Edit,
    CreatePoints,
    EditPoints,
  },
  methods: {
    filterRouters: async function() {
      if(this.search.length >= 3 || this.search.length==0) {
        await this.get_dataRuoter(this.search);
      }
    }, 
    setCodeRouteId:function(data){
      this.codeRouteId=data
    }, 
    setPointId:function(data){
      this.coordinateNew={lat:0,lng:0}
      this.PointsNum=data
    },  
    setPoint: function(e,edit=false,coordinateNew={lat:0,lng:0}){
      this.LatLng=e;
      this.coordinateNew=coordinateNew;
      console.log("coordinateNew-index-71",this.coordinateNew)
      if(edit==true){
        this.PointsNum='';
        this.PointsNum='PX';
        var infoPoint2 = this.dataPoints.filter((infoPoint) =>{
           return infoPoint.Latitude == this.LatLng.lat && 
                  infoPoint.Longitude == this.LatLng.lng    ;
        });
        this.PointsNum=infoPoint2[0].PointsNum;
        document.getElementById("btnEditPoints").click();
      }else{
        document.getElementById("btnCreatePoints").click();
      }  

    },
    setRutaActual:function(data){
      this.rutaActual = {codigo: data.CodeRoute,nombre: data.Description,};
      this.get_dataPoints(data.CodeRoute);
    },
    updateNewRoute:function(data){
      this.search=data.Description;
      this.get_dataRuoter( this.search);
      this.setRutaActual(data);
    },
    async get_nextPointNumber() {
      this.isLoader=true
      const info = await leerSiguiente();
      if (info.result.data.code==200){
        this.PointsNum=info.result.data.data
      }    
      this.isLoader=false;
    },  
    async get_divisionTerritorial() {
      this.isLoader=true
      const result = await leerDivisionTerritorial();
      this.divisionTerritorial = [];
      result.result.data.data.map((item) => {
        this.divisionTerritorial.push({
          name: item.NOMBREDIVISION , 
          value: item.CODIGODIVISION,
        });
      });
      this.isLoader=false;
    },
    async get_dataPoints(CodeRoute){
      this.isLoader=true
      const result = await leerRutaPuntos({"CodeRoute":CodeRoute})
      this.dataPoints = [];
      result.data.data[0].puntos.map((item) => {
        this.dataPoints.push({
          "PointsNum": item.PointsNum,
          "City": item.City,
          "Description": item.Description,
          "Latitud": item.Latitud,
          "Longitud":item.Longitud,
          "Type": item.Type,
          "Latitude": item.Latitude,
          "Longitude": item.Longitude,
        })
      })    
      this.PointsNum='PX' //inicializa punto de referencia
      this.isLoader=false 
    },

    async get_dataRuoter(filter) {
      this.isLoader=true
      const result = await leerRutas({
        page:1,
        pageSize:10,
        filter: filter
      });
      this.dataRouters = [];
      result.result.data.data.map((item) => {
        this.dataRouters.push({
          CodeRoute: item.CodeRoute,
          Description: item.Description,
          Status:item.Status
        });
        if(this.rutaActual.codigo==""){
          this.rutaActual.codigo=item.CodeRoute;
          this.rutaActual.nombre=item.Description;
          this.get_dataPoints(item.CodeRoute);
        }
      });
      this.isLoader=false;
    },  
  },  
  mounted() {
    this.isLoader=true;
    this.get_divisionTerritorial();
    this.isLoader=true;
    this.get_dataRuoter("");
    this.isLoader=false;
  }  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="chat-leftsidebar">
        <div class="px-2 pt-2 mb-2">
          <div class="d-flex align-items-start">
            <div class="flex-grow-1">
              <h5 class="mb-4">Rutas</h5>
            </div>
            <div class="flex-shrink-0">
              <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Adicionar Ruta">
                <!-- Button trigger modal crear rutas -->
                <button type="button" class="btn btn-soft-success btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#myModal">
                  <i class="ri-add-line align-bottom"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="search-box">
            <input type="text" class="form-control bg-light border-light" 
            v-on:keyup="filterRouters" v-model="search"
            placeholder="Buscar ruta (al menos 3 leras)..." />
            <i class="ri-search-2-line search-icon"></i>
          </div>
        </div>
        <!-- .p-4 -->

        <div class="chat-room-list" data-simplebar>
          <div class="chat-message-list">
            <SimpleBar class="list-unstyled chat-list chat-user-list " >
              <table class="table table-info table-striped align-middle table-nowrap mb-0">
                <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Ruta</th>
                      <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr v-for="ruta in dataRouters" :key="ruta.CodeRoute">
                     
                        <th scope="row" > 
                          <a href="javascript:void(0);"  @Click="setRutaActual(ruta)" 
                          :class="ruta.Status=='A'?'link-danger':'link-disable'">{{ruta.CodeRoute}}</a>
                        </th>
                        <td>{{ ruta.Description }}</td>
                        <td>
                            <div class="hstack gap-3 flex-wrap">
                                <a href="javascript:void(0);" class="link-success fs-15" 
                                  data-bs-toggle="modal"  data-bs-target="#myModalEdit"  @click="setCodeRouteId(ruta.CodeRoute)">
                                  <i class="ri-edit-2-line"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
              </table>
            </SimpleBar>
          </div>

          <div class="d-flex align-items-center px-4 mt-4 pt-2 mb-2">
            <div class="flex-grow-1">
              <h4 class="mb-0 fs-11 text-muted text-uppercase">Puntos de Control</h4>
            </div>
            <div class="flex-shrink-0">
              <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Create Points">
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-soft-success btn-sm"
                  id="btnCreatePoints"
                  @click="get_nextPointNumber"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalPoint">
                  <i class="ri-add-line align-bottom"></i>
                </button> 
                <a href="javascript:void(0);" class="link-success fs-15" 
                id="btnEditPoints"
                data-bs-toggle="modal"  
                data-bs-target="#myModalPointEdit" >
                <i class="ri-edit-2-line"></i>
              </a>
              </div>
            </div>
          </div>

          <div class="chat-message-list">
            <div class="list-unstyled chat-list chat-user-list mb-0" id="channelList">
              <table class="table table-info table-striped align-middle table-nowrap mb-0">
                <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Punto</th>
                      <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr v-for="punto in dataPoints" :key="punto.PointsNum">
                        <th scope="row" > 
                          <a href="javascript:void(0);"   
                          class="link-danger">{{punto.PointsNum}}</a>
                        </th>
                        <td>{{ punto.Description }}</td>
                        <td>
                            <div class="hstack gap-3 flex-wrap">
                                <a href="javascript:void(0);" class="link-success fs-15" 
                                  data-bs-toggle="modal"  
                                  data-bs-target="#myModalPointEdit" 
                                  @click="setPointId(punto.PointsNum)">
                                  <i class="ri-edit-2-line"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- End chat-message-list -->
        </div>
      </div>
      <!-- end chat leftsidebar -->
      <!-- Start User chat -->
      <div class="card w-100 overflow-hidden py-0 px-0">
        <div class="card-body">
         <mapRouter class="leaflet-map" 
          @setPoint="setPoint"
          :dataPoints="dataPoints"
          :rutaActual="rutaActual"
         /> 
        </div>
      </div>
    </div>
    <!-- end chat-wrapper -->
    <!-- Myloader -->
    <div v-if="isLoader" style="position:absolute; top: 35%; 
        left:20%;
        z-index: 9999;  "  >
          <myLoader /> 
    </div>
    <Create @newRoute="updateNewRoute"
     :divisionTerritorial="divisionTerritorial"
    />
    <Edit :codeRouteId="codeRouteId"
      :divisionTerritorial="divisionTerritorial" 
      @clearIdRoute="setCodeRouteId"
      @newRoute="updateNewRoute"
    />
    <CreatePoints @newPoint="get_dataPoints" 
       :LatLng="LatLng"
       :PointsNum="PointsNum"
       :rutaActual="rutaActual"
    />
    <EditPoints @newPoint="get_dataPoints" 
      :PointsNum="PointsNum"
      :coordinateNew="coordinateNew"
      @clearIdPoint="setPointId"
      :rutaActual="rutaActual"
    />
    </Layout>
    <!-- end Myloader -->
    <!-- Crear Ruta-->
    <!-- end Crear Ruta -->
</template>