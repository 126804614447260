<script>
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {  helpers,  required, minLength } from "@vuelidate/validators";
import myLoader from "@/components/myLoader.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import {esIdRutaUnico} from "@/api/routers";
import { crearRuta } from "../../../api/routers";

const { withAsync } = helpers 

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  
  data() {
    return {
      visible:false,
      CodeRoute:null,
      Description:null,
      isLoader:false,
      OriginCity:8001000,
      DestinationCity:8001000,
      Status:'A',
      submitted:null,
    }
  },
  components: {
    myLoader,
    Multiselect,
  },
  validations: {
    CodeRoute: {
      required: helpers.withMessage("el ID es requerido", required),
      minLength: helpers.withMessage("Minimo 3 ", minLength(3)),
      isUnique: helpers.withMessage("Se repite",withAsync(async function (value) {
          if(value==null || value.length==0 || value=="" ) return true
          const isOk =await esIdRutaUnico({"CodeRoute":value});
          return isOk.result.data.data[0];
      }))
    },
    Description: {
      required: helpers.withMessage("el nombre es requerido", required),
    },
    OriginCity:{
      required: helpers.withMessage("el origen es requerido", required),
    },
    DestinationCity:{
      required: helpers.withMessage("el destino es requerido", required),
    },
  },  
  props: {
    divisionTerritorial: { type: Array, required: false,
    },
  }, 
  methods: {
    trySubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        // Aquí podrías realizar la lógica para guardar los datos
        this.save();
        document.getElementById("btnCloseModal").click();
      }
      this.isLoader = false;
    },
    async save() {
      this.isLoader=true
      let body={
        "CodeRoute":this.CodeRoute,
        "Description":this.Description,
        "OriginCity":this.OriginCity,
        "DestinationCity":this.DestinationCity,
        "Status":this.Status
      }
      const result = await crearRuta(body);
      this.isLoader=false;
      if (result.data.code==200){
          Swal.fire("Ruta Creada!", "Proceso Realizado con éxito!", "success");
          this.$emit("newRoute",{ 
            "CodeRoute":this.CodeRoute,
            "Description":this.Description,
          });
      }    
    },
   
  },  
  mounted() {
   
  }   
}  
</script>


<!-- Default Modals -->
<template>
  <div id="myModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg drawer-dialog-right">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Creación de Rutas</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form id="createForm" @submit.prevent="trySubmit">
            <div class="row">
    
              <div class="col-md-3">
                <div class="mb-2">
                  <label for="CodeRoute" class="form-label"
                    >Id</label
                  >
                
                  <input
                    type="text"
                    class="form-control"
                    id="CodeRoute"
                    placeholder="ID ruta"
                    v-model="CodeRoute"
                    :class="{
                      'is-invalid': submitted && v$.CodeRoute.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.CodeRoute.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="mb-2">
                  <label for="Description" class="form-label"
                    >Nombre de la Ruta (único)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="Description"
                    placeholder="Nombre de la ruta"
                    v-model="Description"
                    :class="{
                      'is-invalid': submitted && v$.Description.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.Description.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label class="form-label">Origen </label>
                <Multiselect :close-on-select="true" :searchable="true" :create-option="true" 
                  :options="divisionTerritorial"
                  label="name"
                  class="multiselect2"
                  :value="divisionTerritorial.value" 
                  aria-label=".form-select"
                  v-model="OriginCity" id="OriginCity"
                />
                <div class="text-danger fs-7"
                    v-for="(item, index) in v$.OriginCity.$errors"
                    :key="index"
                  >
                  <span  v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label">Destino</label>
                <Multiselect :close-on-select="true" :searchable="true" :create-option="true" 
                  :options="divisionTerritorial"
                  label="name"
                  class="multiselect2"
                  :value="divisionTerritorial.value" 
                  aria-label=".form-select"
                  v-model="DestinationCity" id="DestinationCity"
                />
                <div class="text-danger fs-7"
                v-for="(item, index) in v$.DestinationCity.$errors"
                :key="index"
              >
              <span  v-if="item.$message">{{ item.$message }}</span>
            </div>
              </div>
            </div>  
            
            <div class="row mt-5">
              <div class="col-lg-12">
                  <label class="form-label">Estado</label><br>
                  <div class="mt-4 mt-lg-0">
                    <div class="form-check form-check-inline form-check-success">
                      <input class="form-check-input" type="radio" name="Status" id="StatusA" value="A" v-model="Status" >
                      <label class="form-check-label" for="StatusA">
                          Normal
                      </label>
                    </div>
                    <div class="form-check form-check-inline form-check-warning ">
                        <input class="form-check-input" type="radio" name="Status"
                          id="StatusI" value="I" v-model="Status"  disabled>
                        <label class="form-check-label" for="StatusI">
                            Inhabilitada
                        </label>
                    </div>
                  <div class="form-check form-check-inline form-check-danger ">
                      <input class="form-check-input" type="radio" name="Status" 
                      id="StatusB" value="B" v-model="Status" disabled >
                      <label class="form-check-label" for="StatusB">
                          Borrada
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </form>  
        </div>
        <div class="modal-footer">
          <button type="button" id="btnCloseModal" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="trySubmit">Grabar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoader" style="position:absolute; top: 35%; 
    right:10%;
    z-index: 9999;  "  >
    <myLoader /> 
</div>
</template>


