import axios from "axios";
import { authHeader } from "../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;


//Funcion Leer Rutas
const leerRutas = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/list`, 
    body,{ headers: authHeader()}
    );
    return { result: result };
  } catch (error) {
    if (error.response) {
      localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const esIdRutaUnico = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/esIdRutaUnico`, 
    body,{ headers: authHeader()}
    );
    return { result: result };
  } catch (error) {
    if (error.response) {
      localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const crearRuta = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/crear`, 
    body,{ headers: authHeader()}
    );
    return   result ;
  } catch (error) {
    if (error.response) {
      localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
}
 
 
const leerRuta = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/leer`, 
    body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
    if (error.response) {
      // localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
}  

const leerRutaPuntos = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/leerConPuntos`, 
    body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
    if (error.response) {
      // localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
}  
const editarRuta = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/editar`, 
    body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
    if (error.response) {
      // localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
}  

const borrarRuta = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Routes/borrar`, 
    body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
    if (error.response) {
      // localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
}  


export { leerRutas, esIdRutaUnico, crearRuta, leerRuta, leerRutaPuntos , editarRuta, borrarRuta };
